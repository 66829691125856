.h1-large {
    font-size: 50px;
    line-height: 1.2em;
    font-weight: 700;
}

.h1-medium {
    font-size: 44px;
    line-height: 1.2em;
    font-weight: 700;
}

.h1-regular {
    font-size: 40px;
    line-height: 1.2em;
    font-weight: 700;
}

p {
    font-size: 18px;
}

.wellcome-box {
    background-image: url('../images/home/baner.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 180px;
    padding-bottom: 100px;
    }
.logo-sect{
    position:absolute;
    top: 15px;
    } 
.logo-sect img {
    width: 130px;
    }
.baner-sect {
    padding: 100px 0 0 0;
    }
.app-button {
    display: flex;
    gap: 15px;
    }
.app-button a.play-store {
    background-image: url('../images/home/playstore-min.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 180px;
    height: 54px;
    display: block;
    transition-duration: .3s;
    transition-property: transform;
    transition-timing-function: ease-out;
    }
.app-button a.app-store {
    background-image: url('../images/home/appstore-min.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 180px;
    height: 54px;
    display: block;
    transition-duration: .3s;
    transition-property: transform;
    transition-timing-function: ease-out;
    }
.app-button a:hover {
    transform: translateY(-8px);
    }
.under-line-custom-hr {
    width: 180px;
    height: 5px;
    opacity: 1 !important;
    }
.section-pading {
    padding-top: 100px;
    padding-bottom: 100px;
    }
.box {
    padding: 30px 30px 30px 30px;
    border-radius: 5px 5px 5px 5px;
    box-shadow: 0px 0px 20px 0px rgba(255, 223.9999999999999, 0, 0.1803921568627451);
    }
.box img {
    width: 35px;
    object-fit: contain;
    }
.box h3 {
    font-size: 22px;
    font-weight: 600;
    line-height: 1.2em;
    }
.box p {
    font-size: 16px;
    }
.getstarted {
    background-image: url('../images/home/started-baner.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding: 100px 0 100px 0;
    }
.getstarted::after {
    content: "";
    background-image: linear-gradient(90deg, #ffe000 0%, #ffe000 100%);
    opacity: 0.9;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    z-index: 1;
    }
.getstarted .container {
    position: relative;
    z-index: 2;
}

.started-box img {
    margin: 5px 0 0 0;
}

.started-box h3 {
    font-size: 25px;
    line-height: 1.3em;
    font-weight: 700;
    margin: 0px 0 1px 0;
    }
.slide-custom {
    width: 303px !important;
    margin: 0px 10px;
    }
.slide-custom image {
    width: 100%;
    }
.explore-section .slider-container {
    margin: 100px 0 0 0;
    }
.userlove-section {
    padding: 100px 0 100px 0;
    }
.slide-box {
    padding: 30px 30px 30px 30px;
    box-shadow: 0px 0px 10px 0px rgba(255, 223.9999999999999, 0, 0.27);
    border-radius: 10px;
    background-color: #1a1a1a;
    width: 600px !important;
    margin: 15px 15px;
    }
.slide-box .client-profile img {
    border-radius: 100px;
    }
.slide-box .client-profile {
    display: flex;
    gap: 20px;
    align-items: center;
    }
.slide-box h3 {
    font-size: 20px;
    line-height: 1.2em;
    font-weight: 600;
    text-transform: capitalize;
    }
.slide-box .quote img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin: 0px auto;
    }
.userlove-section .slider-container {
    margin: 50px 0 0 0;
    }
.userlove-section .slick-dots li.slick-active button:before {
    opacity: .75;
    color: #fcf500;
    }
.userlove-section .slick-dots li button:before {
    font-size: 10px;
    }
.userlove-section .slick-dots li button:before {
    font-family: 'slick';
    font-size: 10px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: .25;
    color: #fff800;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    }
.userlove-section .slick-dots li {
    margin: 0px;
    }
.userlove-section .slick-dots {
    position: absolute;
    bottom: -50px;
    }
.join-dual-section {
    background-image: url('../images/home/join-baner.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding: 100px 0 100px 0;
    }
.join-dual-section::after {
    content: "";
    background-image: linear-gradient(90deg, #ffe000 0%, #ffe000 100%);
    opacity: 0.9;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    z-index: 1;
    }
.join-dual-section .container {
    position: relative;
    z-index: 2;
    }
.black-box {
    background: #1a1a1a;
    padding: 30px 0 30px 0;
    border-radius: 20px;
    box-shadow: 0px 0px 30px 0px rgba(26, 26, 26, 0.6392156862745098);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    }
.black-box h3 {
    font-size: 30px;
    }
.black-box p {
    font-size: 20px;
    }
.black-box img {
    width: 60px;
    }
.contact-us-sect {
    padding: 100px 0 100px 0;
    }   
form.contact-us-form input,
form.contact-us-form textarea {
    background: transparent;
    border: 0px;
    border-bottom: 1px solid #ffe000;
    border-radius: 0px;
    padding: 0px 0 20px 10px;
    resize: unset;
    }
form.contact-us-form input:active,
form.contact-us-form input:focus,
form.contact-us-form textarea:active,
form.contact-us-form textarea:focus {
    outline: 0px;
    color: #fff;
    background: transparent;
    box-shadow: inherit;
    border-color: #ffe000;
    }
form.contact-us-form input::placeholder,
form.contact-us-form textarea::placeholder {
    font-size: 18px;
    text-transform: capitalize;
    letter-spacing: 1px;
    padding-left: 10px;
    color: #fff;
    }
form.contact-us-form .send-btn {
    width: 100%;
    background: transparent;
    border: 1px solid #ffe000;
    color: #ffe000;
    text-transform: capitalize;
    padding: 15px 0 15px 0;
    border-radius: 50px;
    font-size: 18px;
    letter-spacing: 1px;
    font-weight: 500;
    }
.contact-us-sect h2 {
    font-size: 22px;
    line-height: 1.2em;
    font-weight: 500;
    }
ul.address-col li {
    color: #fff;
    list-style: none;
    font-size: 18px;
    line-height: 1.5em;
    }
ul.address-col li strong {
    font-size: 22px;
    line-height: 1.5em;
    padding-bottom: 0.2em;
    display: block;
    position: relative;
    }
.cont-col a {
    text-decoration: none;
    font-size: 18px;
    }
.font-18 {
    font-size: 22px;
    }
.a-decuration-text {
    text-decoration: none;
    font-size: 16px;
    }
.cont-col h3 {
    font-size: 22px;
    font-weight: 500;
    line-height: 1.2em;
    }
.terms-sect  ,
.privacy-sect{
    padding: 150px 0 50px;
    }
.terms-sect ul.icon-list ,
.privacy-sect ul.icon-list  {
    padding-left: 10px;
    }
.terms-sect ul.icon-list li ,
.privacy-sect ul.icon-list li {
    list-style: none;
    }
.terms-sect ul.icon-list li a ,
.privacy-sect ul.icon-list li a{
    font-size: 18px;
    line-height: 1.2em;
    display: inline-block;
    padding: 8px 0 8px 0;
    }   
.fot-logo img{
    width: 150px;
    }
.fot-ul {
    display: flex;
    gap: 15px;
    padding: 0px;
    }  
.fot-ul li{
    list-style: none;
    }
.fot-ul li a{
    font-size: 16px;
    display: inline-block;
    }    
.footer-sect{
    box-shadow: 0px 0px 10px 0px #FFE000;
    padding: 50px 0 50px 0;
    }
ul.fot-app-btn {
    padding-left: 10px;
    }
ul.fot-app-btn li {
    list-style: none;
    }    
ul.fot-app-btn li a.play-store{
    background-image: url('../images/home/G1.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 160px;
    height: 54px;
    display: block;
    transition-duration: .3s;
    transition-property: transform;
    transition-timing-function: ease-out;
    margin-bottom: 12px;
    }   
ul.fot-app-btn li a.app-store{
    background-image: url('../images/home/a1-min.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 160px;
    height: 54px;
    display: block;
    transition-duration: .3s;
    transition-property: transform;
    transition-timing-function: ease-out;
    }      
ul.get-touch li {
    display: flex;
    }    
    


/* ---Media Query CSS here ----- */
/* 1400 */
@media (max-width: 1400px) {

    p {
        font-size: 18px;
    }

    .h1-large {
        font-size: 44px;
    }

    .h1-medium {
        font-size: 40px;
    }

    .h1-regular {
        font-size: 36px;
    }
}

/* 1200px */
@media (max-width: 1200px) {
    p {
        font-size: 16px;
    }

    .app-button {
        justify-content: center;
        margin-bottom: 40px;
        flex-wrap: wrap;
    }

    .h1-large {
        font-size: 36px;
    }

    .h1-medium {
        font-size: 34px;
    }

    .h1-regular {
        font-size: 32px;
    }
    .black-box p{
        font-size:16px;
    }  
}


@media (max-width: 992px) {
p {
    font-size: 16px;
    }    
.embrace-section-row{
    flex-direction: column-reverse;
    }

}
@media (max-width: 767px) {
p {
    font-size: 16px;
    }    
.h1-large {
    font-size: 32px;
    }
.slide-box {
    width: 280px !important;
    }
.h1-medium {
    font-size: 30px;
    }
.h1-regular {
    font-size: 28px;
    }
.black-box p{
    font-size:16px;
    }  
.privacy-sect h3 ,
.terms-sect h3{
    font-size: 22px;
    line-height: 1.2em;
    }    
}

@media (max-width: 576px) {
p {
    font-size: 15px;
    }    
.slide-box {
    width: 280px !important;
    }
.black-box p{
    font-size:15px;
    }  
.privacy-sect h3 ,
.terms-sect h3{
    font-size: 20px;
    line-height: 1.2em;
    }  
}